<template>
  <div>
    <b-modal
      v-model="showModal"
      :title="isReportedAlready ? 'Reported Successfully' : 'Report Post'"
      :header-bg-variant="isReportedAlready ? 'primary' : 'warning'"
      :header-text-variant="isReportedAlready ? 'light' : 'default'"
      size="lg"
      centered
      hide-header-close
      no-close-on-backdrop
    >
      <b-container fluid>
        <div v-if="isReportedAlready">
          <p>
            Concern has been Raised! Our Team will View the issue and take
            action accordingly. Thank you
          </p>
        </div>
        <div v-else>
          <b-form-group
            label="Select the appropiate Reason"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              id="radio-group-1"
              v-model="reportReason"
              :options="reportOptions"
              :aria-describedby="ariaDescribedby"
              name="radio-options"
              @change="subInfo = ''"
            ></b-form-radio-group>
          </b-form-group>
          <div class="mt-4">
            <b-form-select
              v-if="reportReason == 'Wrong Language'"
              placeholder="Select Language"
              v-model="subInfo"
              :options="languageOption"
            ></b-form-select>
            <b-form-select
              v-if="reportReason == 'Wrong Category'"
              placeholder="Select Category"
              v-model="subInfo"
              :options="categoryOption"
            ></b-form-select>
            <b-form-input
              v-if="reportReason == 'Problematic Content'"
              v-model="subInfo"
              placeholder="Describe the issue"
            ></b-form-input>
          </div>
        </div>
      </b-container>

      <template #modal-footer>
        <b-button
          size="md"
          variant="outline-primary"
          @click="$emit('closedModal')"
        >
          {{ isReportedAlready ? "Close" : "Cancel" }}
        </b-button>
        <b-button
          v-if="!isReportedAlready"
          size="md"
          variant="outline-danger"
          @click="reportArticle()"
          :disabled="!isButtonEnabled"
        >
          Report
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "../../router/EventBus";
import {
  BModal,
  BButton,
  BFormGroup,
  BFormSelect,
  BFormRadioGroup,
  BFormInput,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormRadioGroup,
    BFormInput,
  },

  props: {
    reportedArticle: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      showModal: true,
      isReportedAlready: false,
      reportReason: "",
      subInfo: "",
      reportOptions: [
        "Wrong Category",
        "Wrong Language",
        "Problematic Content",
      ],
      languageOption: ["English", "Hindi", "Bengali"],
      categoryOption: [
        "Article",
        "Cooking",
        "Jokes",
        "News",
        "Poem",
        "Sports",
        "Travel",
        {
          label: "Stories",
          options: [
            "Educational",
            "Horror",
            "Romance",
            "Short stories",
            "Suspense",
            "Thriller",
          ],
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["userDetails"]),

    isButtonEnabled() {
      return this.reportReason && this.subInfo;
    },
  },

  methods: {
    ...mapActions(["reportpost", "signOut"]),

    async reportArticle() {
      try {
        await this.reportpost({
          reporterId: this.userDetails.id,
          reporterName: this.userDetails.name,
          reportedPostId: this.reportedArticle._id,
          reportedPostTitle: this.reportedArticle.title,
          reportedPostAuthor: this.reportedArticle.author,
          reportReason: this.reportReason,
          reportSubInfo: this.subInfo,
        });

        this.isReportedAlready = true;
      } catch (e) {
        if (e.response.status === 401) {
          this.signOut();
        } else {
          this.$emit("closedModal");
          EventBus.$emit("showCommonModal", {
            title: "Error",
            content: "Error Occurred. Please try again!",
            variant: "danger",
          });
        }
      }
    },
  },
};
</script>
